<template>
  <div class="wrapper">
    <div class="preview">
      <h2 class="heading-2 text-center">
        {{ $t("trackingSettingsPage.trackingPagePreview") }}
      </h2>
      <div class="tracking-page">
        <TrackingTemplate class="template" :order="orderProps" :lang="false" />
      </div>
    </div>
    <div class="text-center preview-link">
      <a href="#" class="link-2" @click="fullPage = true">
        {{ $t("trackingSettingsPage.viewInFullScreen") }} >
      </a>
    </div>

    <b-modal v-model="fullPage" modal-class="template-modal" size="xl">
      <ModalTrackingPage :order="orderProps" />

      <template #modal-footer="{ ok }">
        <NbButton @click="ok">{{ $t("close") }}</NbButton>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TrackingTemplate from "../../trackings/TrackingTemplate.vue";
import ModalTrackingPage from "./ModalTrackingPage.vue";
import NbButton from "@/components/buttons/NbButton.vue";

export default {
  components: {
    TrackingTemplate,
    ModalTrackingPage,
    NbButton,
  },
  props: {
    sellerSettings: {
      type: Object,
      required: true,
    },
    trackingSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fullPage: false,
    };
  },
  computed: {
    orderProps() {
      const sellerData = this.sellerSettings;
      const trackingData = this.trackingSettings;
      return {
        order_number: "123",
        estimated_delivery_date: null,
        customer_city: "Brasília",
        customer_state: "DF",
        customer_country: "BR",
        volumes: [
          {
            last_mile_tracking_number: "NB12345678910BR",
            order_items: [
              {
                description: "Blue Media",
              },
            ],
            current_status: {
              tracking_code: 100,
            },
          },
        ],
        seller: {
          name: sellerData.name,
          site: sellerData.site,
          show_logo: sellerData.show_logo,
          tracking_setting: {
            primary_color: trackingData?.primary_color,
            secondary_color: trackingData?.secondary_color,
            text_color: trackingData?.text_color,
            facebook: trackingData?.facebook,
            twitter: trackingData?.twitter,
            instagram: trackingData?.instagram,
            linkedin: trackingData?.linkedin,

            show_logo: trackingData?.show_logo,
            seller_id: 1,
            website: trackingData?.website,
            logo_fallback_type: trackingData?.logo_fallback_type,
            logo_fallback_text: trackingData?.logo_fallback_text,
            contact_us_action_click: trackingData?.contact_us_action_click,
            contact_us_action_subject: trackingData?.contact_us_action_subject,
            ad_img: trackingData?.ad_img,

            ad_url: trackingData?.ad_url,
            ad_main_text: trackingData?.ad_main_text,
            ad_link_text_action: trackingData?.ad_link_text_action,
            footnote: trackingData?.footnote,
            show_ad: trackingData?.show_ad,
            show_footnote: trackingData?.show_footnote,
            show_contact_info: trackingData?.show_contact_info,
            redirect_to_website_when_click_logo:
              trackingData?.redirect_to_website_when_click_logo,
            redirect_to_website_when_click_footnote:
              trackingData?.redirect_to_website_when_click_footnote,
          },
          logo: sellerData?.logo,
        },
        contract: {
          name: "Correios Standard",
        },
        order_checkpoints: [
          {
            date_iso: "2022-03-25T20:30:47.000Z",
            macro_status: {
              name: "DELIVERED",
              description: "The shipment was delivered successfully",
              description_pt: "A remessa foi entregue com sucesso",
            },
          },
          {
            date_iso: "2022-03-25T20:21:06.000Z",
            macro_status: {
              name: "NEW",
              description: "The order has been created in the platform",
              description_pt: "A remessa foi criada",
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: var(--gray-05);
  border-radius: 8px;
  height: 565px;
  .preview {
    max-height: 535px;
    width: 490px;
    padding: 20px;
    overflow: hidden;

    .template {
      width: 1280px;
      -ms-transform: scale(0.35);
      -moz-transform: scale(0.35);
      -o-transform: scale(0.35);
      -webkit-transform: scale(0.35);
      transform: scale(0.35);

      -ms-transform-origin: 0 0;
      -moz-transform-origin: 0 0;
      -o-transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
    }
  }

  .tracking-page {
    max-height: 480px;
  }

  .preview-link {
    margin-top: 0.2rem;
  }
}

::v-deep .modal-dialog {
  width: 100%;
  max-width: 100%;
  top: 0;
  margin: 0;
}
</style>
