<template>
  <TrackingTemplate :order="order" />
</template>

<script>
import TrackingTemplate from "../../trackings/TrackingTemplate.vue";
export default {
  name: "ModalTrackingPage",
  components: { TrackingTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
