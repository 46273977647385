<template>
  <label class="nb-radio-label">
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: "RadioGroupLabel",
};
</script>

<style lang="scss" scoped>
.nb-radio-label {
  color: var(--black);
  font-family: var(--font-family-base);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: 0.625rem;
}
</style>
