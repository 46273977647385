<template>
  <div class="nb-radio-group-option" tabindex="1" @keydown="onKeydown">
    <div>
      <input
        v-model="modelValue"
        ref="nb-radio-input"
        type="radio"
        :id="reference"
        :value="value"
        @change="onChange"
      />
    </div>

    <label :for="reference" class="m-0 body-3">
      {{ label }}
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  name: "RadioGroupOption",
  props: {
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    reference() {
      return `option-${this._uid}`;
    },
    modelValue: {
      get() {
        return this.$parent.$props?.value;
      },
      set(value) {
        this.$parent.$emit("input", value);
      },
    },
  },
  methods: {
    onKeydown(event) {
      switch (event.code) {
        case "Space":
        case "Enter":
          {
            event.preventDefault();
            this.$refs?.["nb-radio-input"]?.click();
          }
          break;
        case "ArrowDown":
        case "ArrowRight":
          event.preventDefault();
          event.target?.nextElementSibling?.focus();
          break;
        case "ArrowUp":
        case "ArrowLeft":
          event.preventDefault();
          event.target?.previousElementSibling?.focus();
          break;
      }
    },
    onChange() {
      this.$el?.focus();
      this.$parent.$emit("change");
    },
  },
};
</script>
<style lang="scss" scoped>
.nb-radio-group-option {
  display: flex;
  gap: 0.375rem;
  & + .nb-radio-group-option {
    margin-top: 0.875rem;
  }
  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--gray-60);
    width: 100%;
  }
  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--gray-05);
    margin: 0;
    font: inherit;
    color: var(--gray-60);
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid var(--gray-60);
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: var(--gray-20);
      border-color: var(--gray-40);
    }
    &:focus {
      outline: none;
      border-color: var(--black);
      &::before {
        border: 1px solid var(--gray-60);
      }
    }
    &::before {
      content: "";
      border: 1px solid transparent;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
    }
    &:checked {
      & + label {
        color: var(--black);
      }
      &::before {
        transform: scale(1);
        background-color: var(--black);
      }
    }
  }
}
</style>
