<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <NbPageTitle
      :title="$t('trackingSettingsPage.title')"
      :subTitle="$t('trackingSettingsPage.subtitle')"
    />
    <div class="sections">
      <div class="settings">
        <NbCardBox class="nb-card section-colors">
          <div class="header">
            <div>
              <h3 class="heading-3 m-0">
                {{ $t("trackingSettingsPage.colors.title") }}
              </h3>
              <p class="body-3 m-0">
                {{ $t("trackingSettingsPage.colors.subtitle") }}
              </p>
            </div>
          </div>

          <div class="content mb-3">
            <NbPickColor
              v-model="tracking_settings.primary_color"
              :label="$t('trackingSettingsPage.colors.foregroundColor')"
              @input="startUpdateTimer()"
            />
            <NbPickColor
              v-model="tracking_settings.secondary_color"
              :label="$t('trackingSettingsPage.colors.backgroundColor')"
              @input="startUpdateTimer()"
            />
            <NbPickColor
              v-model="tracking_settings.text_color"
              :label="$t('trackingSettingsPage.colors.text_color')"
              @input="startUpdateTimer()"
            />
          </div>
          <div class="mb-5">
            <a @click="setDefaultColors()" class="link-2 float-right">
              {{ $t("trackingSettingsPage.colors.returnsToDefaultColors") }}
            </a>
          </div>
        </NbCardBox>

        <NbCardBox class="nb-card">
          <div class="header">
            <div>
              <h3 class="heading-3 m-0">
                {{ $t("trackingSettingsPage.brand.title") }}
              </h3>
              <p class="body-3 m-0">
                {{ $t("trackingSettingsPage.brand.subtitle") }},
                <router-link class="link-1" to="/company"
                  >{{ $t("trackingSettingsPage.brand.changeBrandIn") }}
                  {{ $t("myCompanyName") }}.</router-link
                >
              </p>
            </div>
            <NbSwitch
              v-model="tracking_settings.show_logo"
              name=""
              id="color"
              @change="
                resetTrakingKeys({
                  logo_fallback_type: 'any_text',
                  logo_fallback_text: '',
                  redirect_to_website_when_click_logo: true,
                });
                startUpdateTimer();
              "
            />
          </div>

          <div v-show="tracking_settings.show_logo" class="content">
            <NbRadioGroup
              v-model="tracking_settings.logo_fallback_type"
              :label="$t('trackingSettingsPage.brand.thereIsNoRegisteredBrand')"
              @input="startUpdateTimer()"
            >
              <NbRadioGroupOption
                value="any_text"
                class="d-flex align-items-center"
                :label="$t('trackingSettingsPage.brand.insertOtherText')"
              >
                <NbTextInput
                  v-model="tracking_settings.logo_fallback_text"
                  :disabled="
                    tracking_settings.logo_fallback_type !== 'any_text'
                  "
                  class="flex-grow-1"
                  id="other-text"
                  :placeholder="
                    $t('trackingSettingsPage.brand.insertOtherTextPlaceholder')
                  "
                  @input="startUpdateTimer()"
                />
              </NbRadioGroupOption>
              <NbRadioGroupOption
                value="company_name"
                :label="$t('trackingSettingsPage.brand.insertCompanyName')"
              >
                <router-link class="link-1" to="/company"
                  >(cadastrado em Minha Empresa)</router-link
                >
              </NbRadioGroupOption>
            </NbRadioGroup>

            <NbRadioGroup
              class="mt-4"
              v-model="tracking_settings.redirect_to_website_when_click_logo"
              :label="$t('trackingSettingsPage.brand.onClickBrand')"
              @input="startUpdateTimer()"
            >
              <NbRadioGroupOption
                :value="true"
                :label="
                  $t('trackingSettingsPage.brand.forwardToCompanyWebsite')
                "
              >
                <router-link class="link-1" to="/company"
                  >(cadastrado em Minha Empresa)</router-link
                >
              </NbRadioGroupOption>
              <NbRadioGroupOption
                :label="$t('trackingSettingsPage.brand.brandIsNotClickable')"
                :value="false"
              />
            </NbRadioGroup>
          </div>
        </NbCardBox>

        <NbCardBox class="nb-card">
          <div class="header">
            <div>
              <h3 class="heading-3 m-0">
                {{ $t("trackingSettingsPage.contacts.title") }}
              </h3>
              <p class="body-3 m-0">
                {{ $t("trackingSettingsPage.contacts.subtitle") }}
              </p>
            </div>
            <NbSwitch
              v-model="tracking_settings.show_contact_info"
              name=""
              id="color"
              @change="
                resetTrakingKeys({
                  facebook: '',
                  instagram: '',
                  twitter: '',
                  linkedin: '',
                });
                resetDisplaySocialNetworks({
                  facebook: false,
                  instagram: false,
                  twitter: false,
                  linkedin: false,
                });
                resetContactUsAttributes();
                startUpdateTimer();
              "
            />
          </div>

          <div v-show="tracking_settings.show_contact_info" class="content">
            <div class="social mb-2">
              <label class="input-label">{{
                $t("trackingSettingsPage.contacts.selectSocialNetwork")
              }}</label>
              <div class="d-flex justify-content-between mb-2 mr-5">
                <NbCheckbox
                  v-model="displaySocialNetworks.facebook"
                  :value="displaySocialNetworks.facebook"
                  name="Facebook"
                  id="facebook-checkbox"
                  @input="
                    resetTrakingKeys({
                      facebook: '',
                    });
                    startUpdateTimer();
                  "
                />
                <NbCheckbox
                  v-model="displaySocialNetworks.instagram"
                  :value="displaySocialNetworks.instagram"
                  name="Instagram"
                  id="instagram-checkbox"
                  @input="
                    resetTrakingKeys({
                      instagram: '',
                    });
                    startUpdateTimer();
                  "
                />
                <NbCheckbox
                  v-model="displaySocialNetworks.twitter"
                  :value="displaySocialNetworks.twitter"
                  name="Twitter"
                  id="twitter-checkbox"
                  @input="
                    resetTrakingKeys({
                      twitter: '',
                    });
                    startUpdateTimer();
                  "
                />
                <NbCheckbox
                  v-model="displaySocialNetworks.linkedin"
                  :value="displaySocialNetworks.linkedin"
                  name="linkedin"
                  id="linkedin-checkbox"
                  @input="
                    resetTrakingKeys({
                      linkedin: '',
                    });
                    startUpdateTimer();
                  "
                />
              </div>

              <div>
                <NbTextInput
                  v-show="displaySocialNetworks.facebook"
                  v-model="tracking_settings.facebook"
                  id="facebook"
                  placeholder="https://facebook.com/account"
                  name="Facebook"
                  @input="startUpdateTimer()"
                />
                <NbTextInput
                  v-show="displaySocialNetworks.instagram"
                  v-model="tracking_settings.instagram"
                  id="instagram"
                  placeholder="https://instagram.com/account"
                  name="Instagram"
                  class="my-2"
                  @input="startUpdateTimer()"
                />
                <NbTextInput
                  v-show="displaySocialNetworks.twitter"
                  v-model="tracking_settings.twitter"
                  id="twitter"
                  placeholder="https://twitter.com/account"
                  name="Twitter"
                  @input="startUpdateTimer()"
                />
                <NbTextInput
                  v-show="displaySocialNetworks.linkedin"
                  v-model="tracking_settings.linkedin"
                  id="linkedin"
                  placeholder="https://linkedin.com/account"
                  name="linkedin"
                  @input="startUpdateTimer()"
                />
              </div>
            </div>

            <NbRadioGroup
              v-model="tracking_settings.contact_us_action_click"
              :label="$t('trackingSettingsPage.contacts.contactUsClick')"
              @input="
                updateContactActionSubject();
                resetContactUsAttributes();
                startUpdateTimer();
              "
            >
              <NbRadioGroupOption
                value="send_mail"
                class="d-flex align-items-center"
                :label="$t('trackingSettingsPage.contacts.sendMailTo')"
              >
                <NbTextInput
                  v-model="contact_us_mail"
                  :disabled="
                    tracking_settings.contact_us_action_click !== 'send_mail'
                  "
                  class="flex-grow-1"
                  id="mail-to"
                  placeholder="example@mail.com"
                  @input="
                    updateContactActionSubject();
                    startUpdateTimer();
                  "
                />
              </NbRadioGroupOption>
              <NbRadioGroupOption
                value="redirect"
                class="d-flex align-items-center"
                :label="$t('trackingSettingsPage.contacts.forwartToWebsite')"
              >
                <NbTextInput
                  v-model="contact_us_website"
                  :disabled="
                    tracking_settings.contact_us_action_click !== 'redirect'
                  "
                  class="flex-grow-1"
                  id="mail-to"
                  placeholder="https://example.com"
                  @input="
                    updateContactActionSubject();
                    startUpdateTimer();
                  "
                />
              </NbRadioGroupOption>
            </NbRadioGroup>
          </div>
        </NbCardBox>

        <NbCardBox class="nb-card">
          <div class="header">
            <div>
              <h3 class="heading-3 m-0">
                {{ $t("trackingSettingsPage.ad.title") }}
              </h3>
              <p class="body-3 m-0">
                {{ $t("trackingSettingsPage.ad.subtitle") }}
              </p>
            </div>
            <NbSwitch
              v-model="tracking_settings.show_ad"
              name=""
              id="ad"
              @change="
                resetTrakingKeys({
                  ad_main_text: '',
                  ad_link_text_action: '',
                  ad_url: '',
                });
                startUpdateTimer();
              "
            />
          </div>

          <div v-show="tracking_settings.show_ad" class="content">
            <NbDropFile
              v-model="file"
              :label="$t('trackingSettingsPage.ad.fileDropLabel') + '*'"
              :accept="['png', 'jpg', 'jpeg', 'webp']"
              class="mb-2"
              @input="onFile"
            >
              <div class="text-center">
                <p class="body-3">
                  {{ $t("trackingSettingsPage.ad.chooseImage") }}
                </p>

                <NbButton variant="secondary" size="sm"
                  >{{ $t("add") }} {{ $t("ad") }}</NbButton
                >
              </div>
            </NbDropFile>

            <NbTextInput
              v-model="tracking_settings.ad_main_text"
              id="main-text"
              :name="$t('trackingSettingsPage.ad.mainText')"
              placeholder="Conheça a @NomeDaEmpresa."
              @input="startUpdateTimer()"
            />

            <NbTextInput
              v-model="tracking_settings.ad_link_text_action"
              id="text-link"
              :name="$t('trackingSettingsPage.ad.linkText')"
              :placeholder="
                $t('trackingSettingsPage.ad.linkTextPlaceholder') + '>'
              "
              @input="startUpdateTimer()"
            />

            <NbTextInput
              v-model="tracking_settings.ad_url"
              id="banner-link"
              :name="$t('trackingSettingsPage.ad.bannerLink')"
              placeholder="mycompany.com"
              @input="startUpdateTimer()"
            />
          </div>
        </NbCardBox>

        <NbCardBox class="nb-card">
          <div class="header">
            <div>
              <h3 class="heading-3 m-0">
                {{ $t("trackingSettingsPage.footer.title") }}
              </h3>
              <p class="body-3 m-0">
                {{ $t("trackingSettingsPage.footer.subtitle") }}
              </p>
            </div>
            <NbSwitch
              v-model="tracking_settings.show_footnote"
              name=""
              id="footer"
              @change="
                resetTrakingKeys({
                  footnote: '',
                  redirect_to_website_when_click_footnote: false,
                });
                startUpdateTimer();
              "
            />
          </div>
          <div v-show="tracking_settings.show_footnote" class="content">
            <NbTextInput
              v-model="tracking_settings.footnote"
              id="footnote"
              :name="$t('trackingSettingsPage.footer.footnote')"
              placeholder="Obrigado por comprar conosco! A @NomeDaEmpresa agradece."
              class="mb-2"
              @input="startUpdateTimer()"
            />

            <NbCheckbox
              v-model="
                tracking_settings.redirect_to_website_when_click_footnote
              "
              id="checkbox-2"
              :name="$t('trackingSettingsPage.footer.onClickAction')"
              @input="startUpdateTimer()"
            />
          </div>
        </NbCardBox>

        <!-- <div class="d-flex justify-content-end mt-4">
          <NbButton variant="secondary">{{ $t("cancel") }}</NbButton>
          <NbButton class="ml-2" @click="startUpdateTimer()">{{
            $t("save")
          }}</NbButton>
        </div> -->
      </div>

      <div class="preview">
        <div class="preview-content" ref="tracking">
          <TrackingPagePreview
            :sellerSettings="sellerSettingsData"
            :trackingSettings="trackingSettingsData"
          />
        </div>
      </div>
    </div>

    <NbFooter
      :text="$t('components.footer.checkDocumentsAndTags')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
  </div>
</template>
<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbPickColor from "@/components/input/pick-color/NbPickColor.vue";
import NbDropFile from "../../components/input/drop-file/NbDropFile.vue";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbRadioGroup from "@/components/input/radio/NbRadioGroup.vue";
import NbRadioGroupOption from "@/components/input/radio/NbRadioGroupOption.vue";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import TrackingPagePreview from "./components/TrackingPagePreview.vue";
import SellerService from "../../services/SellerService";
import UserService from "../../services/UserService";

const sellerService = new SellerService();
const userService = new UserService();

export default {
  name: "TrackingPage",
  components: {
    NbPageTitle,
    NbFooter,
    NbSwitch,
    NbTextInput,
    NbCheckbox,
    NbPickColor,
    NbDropFile,
    NbButton,
    NbRadioGroup,
    NbRadioGroupOption,
    TrackingPagePreview,
    LoadingPage,
    NbCardBox,
  },
  data() {
    return {
      loaded: true,
      timer: null,
      file: "",
      displaySocialNetworks: {
        facebook: false,
        instagram: false,
        twitter: false,
        linkedin: false,
      },
      defaultColors: {
        primary_color: "#001991",
        secondary_color: "#F2F2F2",
        text_color: "#FFFFFF",
      },
      seller: {},
      tracking_settings: {
        id: null,
        seller_id: null,
        website: "",
        logo_fallback_type: "",
        logo_fallback_text: "",
        contact_us_action_click: "",
        contact_us_action_subject: "",
        ad_img: "",
        ad_url: "",
        ad_main_text: "",
        ad_link_text_action: "",
        footnote: "",
        primary_color: "#001991",
        secondary_color: "#F2F2F2",
        text_color: "#FFFFFF",
        facebook: "",
        twitter: "",
        linkedin: "",
        instagram: "",
        show_ad: false,
        show_logo: false,
        show_footnote: false,
        show_contact_info: false,
        redirect_to_website_when_click_logo: false,
        redirect_to_website_when_click_footnote: false,
      },
      adTempUrl: "",
      contact_us_mail: "",
      contact_us_website: "",
    };
  },
  computed: {
    trackingSettingsData() {
      return {
        ...this.tracking_settings,
        ad_img: this.adTempUrl || this.tracking_settings.ad_img,
      };
    },
    sellerSettingsData() {
      return {
        ...this.tracking_settings,
        name: this.seller.name,
        site: this.seller.site,
        logo: this.seller.logo_url,
      };
    },
    contactUsSubject() {
      if (this.tracking_settings?.contact_us_action_click === "send_mail") {
        return this.contact_us_mail;
      }

      return this.contact_us_website;
    },
  },
  methods: {
    siwtchIsSaving(savingVal) {
      if (typeof savingVal === "boolean") {
        this.$root.$emit("isSaving", savingVal);
        return;
      }
      this.$root.$emit("isSaving", false);
    },
    resetTrakingKeys(reset_keys) {
      this.tracking_settings = Object.assign(
        {},
        this.tracking_settings,
        reset_keys
      );
    },
    resetDisplaySocialNetworks(reset_keys) {
      this.displaySocialNetworks = Object.assign(
        {},
        this.displaySocialNetworks,
        reset_keys
      );
    },
    updateContactActionSubject() {
      this.tracking_settings.contact_us_action_subject = this.contactUsSubject;
    },
    resetContactUsAttributes() {
      this.contact_us_mail = "";
      this.contact_us_website = "";
    },
    setDefaultColors() {
      this.tracking_settings.primary_color = this.defaultColors.primary_color;
      this.tracking_settings.secondary_color =
        this.defaultColors.secondary_color;
      this.tracking_settings.text_color = this.defaultColors.text_color;

      this.startUpdateTimer();
    },
    onFile(file) {
      if (!file) {
        this.adTempUrl = "";
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const result = e.target.result;
        this.adTempUrl = result;
      };

      reader.readAsDataURL(file);
      this.startUpdateTimer();
    },
    async loadSettings() {
      const user = userService.getLoggedInUser();
      if (user.profile === "seller") {
        try {
          const { data } = await sellerService.getCurrentSeller();
          const seller = data.data;
          this.seller = seller;

          const tracking = await sellerService.getTrackingSettings(
            seller.tracking_setting.id
          );

          if (tracking) {
            this.tracking_settings = Object.keys({
              ...this.tracking_settings,
              ...tracking.data.data,
            }).reduce((resultado, chave) => {
              if (
                tracking.data.data[chave] !== undefined &&
                tracking.data.data[chave] !== null
              ) {
                resultado[chave] = tracking.data.data[chave];
              } else {
                resultado[chave] = this.tracking_settings[chave];
              }
              return resultado;
            }, {});

            if (this.tracking_settings.facebook) {
              this.displaySocialNetworks.facebook = true;
            }
            if (this.tracking_settings.instagram) {
              this.displaySocialNetworks.instagram = true;
            }
            if (this.tracking_settings.twitter) {
              this.displaySocialNetworks.twitter = true;
            }
            if (this.tracking_settings.linkedin) {
              this.displaySocialNetworks.linkedin = true;
            }

            if (!this.tracking_settings.primary_color) {
              this.displaySocialNetworks.primary_color = "#001991";
            }
            if (!this.tracking_settings.secondary_color) {
              this.displaySocialNetworks.secondary_color = "#F2F2F2";
            }
            if (!this.tracking_settings.text_color) {
              this.displaySocialNetworks.text_color = "#FFFFFF";
            }

            if (
              this.tracking_settings.contact_us_action_click === "send_mail"
            ) {
              this.contact_us_mail =
                this.tracking_settings.contact_us_action_subject;
            } else if (
              this.tracking_settings.contact_us_action_click === "redirect"
            ) {
              this.contact_us_website =
                this.tracking_settings.contact_us_action_subject;
            }
          }
        } finally {
          this.loaded = false;
        }
      }
    },
    startUpdateTimer() {
      this.siwtchIsSaving(true);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.updateSettings();
      }, 2000);
    },
    async updateSettings() {
      try {
        await sellerService.updateTrackingSettings(
          this.tracking_settings.id,
          this.tracking_settings
        );

        if (this.file) {
          await sellerService.uploadAd(this.seller.id, this.file);
        }
      } catch (error) {
        const errors = this.$helpers.extractErrorMessages(error);
        errors.forEach((message) => {
          this.$helpers.toast(message, "danger");
        });
      } finally {
        this.siwtchIsSaving(false);
      }
    },
  },
  created() {
    this.loadSettings();
  },
};
</script>

<style scoped lang="scss">
.nb-card {
  & + .nb-card {
    margin-top: 0.5rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }

  .content {
    padding-top: 1.5rem;
    border-top: 1px solid var(--gray-10);
  }
}

.settings {
  max-width: 32.75rem;
  width: 100%;
}
.settings .nb-card {
  width: 32.75rem;
}

.section-colors {
  .content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    @media screen and (min-width: 750px) {
      flex-direction: row;
      align-items: flex-end;
      gap: 1.5rem;
      & > *:not(a) {
        flex: 1;
      }

      a {
        flex-basis: 5.6rem;
      }
    }
  }
}

.sections {
  display: flex;
  position: relative;
}

.preview {
  position: sticky;
  top: 0px;
  margin-left: 3.12rem;
  height: min-content;
}
</style>
