<script>
import NbRadioGroupLabel from "./NbRadioGroupLabel.vue";
export default {
  name: "NbRadioGroup",
  props: {
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  watch: {
    value(newVal) {
      this.$emit("input", newVal);
    },
  },
  render(createElement) {
    return createElement("div", [
      this.label && createElement(NbRadioGroupLabel, this.label),
      ...this.$slots.default,
    ]);
  },
};
</script>

<style lang="scss" scoped></style>
